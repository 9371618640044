var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"services"},[_c('b-container',{attrs:{"fluid":"xl"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'fade-up'); }),expression:"(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"}],staticClass:"animateScroll",domProps:{"innerHTML":_vm._s(_vm.tr('home_services_title'))}})])],1),_c('b-row',[_c('b-col',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'fade-up'); }),expression:"(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"}],staticClass:"animateScroll",attrs:{"cols":"12"}},[_c('OwlCarousel',{attrs:{"items":4,"margin":40,"dots":true,"loop":false,"nav":false,"responsive":{ 
                                0:{
                                    items:1,														
                                    margin: 0,
                                    mouseDrag: true,
                                    loop:true,
                                    dots:true                                        
                                },      													
                                768:{items:4, mouseDrag:true, margin: 30},
                                992:{items:4, mouseDrag:true, margin:20}
                            }}},_vm._l((_vm.slides),function(slide,sindex){return _c('div',{key:sindex,staticClass:"img-wrap-outer"},[_c('div',{staticClass:"img-wrap"},[_c('img',{staticClass:"img-fluid",attrs:{"src":'/pictures/services/slider/' + slide.src}})]),_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'fade-up', 'delay-1'); }),expression:"(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')"}],staticClass:"name animateScroll"},[_vm._v(_vm._s(slide.name[_vm.selectedLanguage]))]),_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'fade-up', 'delay-2'); }),expression:"(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')"}],staticClass:"text animateScroll"},[_vm._v(_vm._s(slide.text[_vm.selectedLanguage]))])])}),0),_c('div',{staticClass:"d-flex justify-content-center"},[_c('router-link',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (isVisible, entry) { return _vm.visibilityChanged(isVisible, entry, 'fade-up', 'delay-4'); }),expression:"(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-4')"}],staticClass:"btn btn--black btn--arrow animateScroll",attrs:{"to":{name: 'services'}}},[_c('span',[_vm._v(_vm._s(_vm.tr('btn_learn_more')))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }