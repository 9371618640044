<template>
    <section class="project" id="sectionProject">
        <b-container fluid="xl">
            <b-row>                
                <b-col cols="12" lg="6" xl="5" >
                    <div class="project__left-block border-line-wrap">
                        <div class="project__text-wrap">
                            <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')" v-html="tr('project_title')"></h2>
                            <div class="project__lead animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">
                                {{tr('project_lead')}}
                            </div>             
                            <img src="/pictures/project/project-home-page.jpg" alt="Luminor Apartments" class="w-100 d-md-none animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')"/>
                            <div class="project__text animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">{{tr('project_text')}}                          
                            </div>
                            <p class="animateScroll project__date project__text" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-4')">{{tr('project_end')}}</p>
                            <router-link :to="{name: 'project'}" class="btn btn--black btn--arrow animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-5')"><span>{{tr('btn_learn_more')}}</span></router-link>
                            <div class="border-line animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'border-anim', 'delay-6')"></div>
                        </div>                            
                    </div>                 
                </b-col>
                <b-col cols="12" lg="6" offset-xl="1" class="project__img-wrap d-none d-md-flex align-items-center">
                    <div class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">
                        <img src="/pictures/project/project-home-page.jpg" alt="Luminor Apartments" class="w-100" />
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <div class="bgr-img animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
            <img src="@/assets/images/bgr/luminor-panorama-03-mobil.jpg" />
        </div>
    </section>
</template>


<script>

export default {
    name: 'Project',
    data(){
        return{   
           
        }
    },
    mounted(){
        
    }  
}
</script>
