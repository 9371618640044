<template>
    <section class="choose-building apartments" id="sectionApartments">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')" v-html="tr('choose_building_title')"></h2>        
                    <p class="page-lead animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">{{tr('choose_building_lead')}}</p>                  
                    <b-tabs 
                        no-fade
                        v-model="tabIndex" 
                        content-class="apartments__tab-content"                         
                        nav-wrapper-class="apartments__tab-head"
                    >   
                 
                        <b-tab>
                            <template v-slot:title>                                
                                <!-- <div @mouseover="showCurrentTab(-1)">
                                    {{tr('home_inter')}}
                                </div> -->
                                
                            </template>                            
                            <img :src="baseUrl + 'pictures/choose_buildings/all_' + selectedLanguage +'.jpg'" class="w-100" />                            
                        </b-tab>   
                        <b-tab 
                            v-for="(apartment, apIndex) in apartments" :key="apIndex"                            
                        >
                            <template v-slot:title>
                                <div @mouseover="showCurrentTab(apIndex)" class="custom-b-tab-title">
                                    <router-link class="d-none d-md-block animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', `delay-${apIndex}`)" :to="{ name: 'levels', params:{ building_name: apartment.slug }}">
                                        {{ apartment.name[selectedLanguage] }}
                                    </router-link>
                                    <div class="d-md-none">{{ apartment.name[selectedLanguage] }}</div>
                                </div>
                            </template>                            
                            <img :src="baseUrl + apartment.buildingImgSrc[selectedLanguage]" :alt="apartment.name[selectedLanguage]" class="w-100" />
                            <router-link  
                                :to="{ name: 'levels', params:{ building_name: apartment.slug }}" 
                                class="btn btn--black btn--arrow d-md-none">
                                <span>{{tr('btn_learn_more')}}</span>
                            </router-link>
                        </b-tab>                                                                                      
                    </b-tabs>        
                </b-col>                                    
            </b-row>
        </b-container>     
    </section>
</template>

<script>


export default {
    data(){
        return{
              tabIndex: -1
        }
    },
    computed:{
        apartments(){
            return this.$store.state.apartments
        }
    },
    methods:{
        showCurrentTab(index){            
            this.tabIndex = index + 1
        }
    }
    
}
</script>