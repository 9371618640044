<template>
  <div class="home">   
      <Hero />       
      <Where />
      <Location :isPage="false"/>
      <Project />      
      <ChooseBuilding />      
      <Interior />
      <Services />
      <Contact />     
      <Partners />        
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import Where from '@/components/Where.vue'
import Contact from '@/components/Contact.vue'
import Project from '@/components/Project.vue'
import Location from '@/components/Location.vue'
import ChooseBuilding from '@/components/ChooseBuilding.vue'
import Interior from '@/components/Interior.vue'
import Services from '@/components/Services.vue'
import Partners from '@/components/Partners.vue'

export default {
  name: 'Home',
  
  components: {
    Hero,
    Where,
    Contact,
    Project,
    Location,
    ChooseBuilding,
    Interior,
    Services,
    Partners
  },
  
}
</script>
