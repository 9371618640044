<template>
    <section class="contact" id="sectionContact">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')" v-html="tr('contact_title')"></h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" lg="6"> 
                    <div class="contact__data">
                        <ValidationObserver v-slot="{ handleSubmit }" ref="contactform">
                            <b-form novalidate @submit.prevent="handleSubmit(onSubmit)">
                                
                                    <b-row>
                                        <b-col md="6">
                                              <ValidationProvider                                                    
                                                    rules="required|min:3"
                                                    v-slot="{ failedRules }"
                                                >
                                                <b-form-group                                         
                                                    id="contact-fname"
                                                    :label="tr('contact_input_fname')"
                                                    label-for="contactFirstName"  
                                                    class="animateScroll"    
                                                    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')"                          
                                                >
                                                    <div class="form-input-wrap">
                                                        <b-form-input
                                                            class="animateScroll"
                                                            id="contactFirstName"
                                                            v-model="form.firstname"
                                                            type="text"
                                                            :placeholder="tr('contact_input_fname_placeholder')"
                                                            @focus="focusedParent($event)"
                                                            @focusout="focusedOutParent($event)"
                                                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"
                                                            ></b-form-input>
                                                    </div>
                                                    <div class="error" id="first-name-error">
                                                        {{ getErrorMessage("forname", Object.entries(failedRules)[0]) }}
                                                    </div>
                                                </b-form-group>                                                
                                              </ValidationProvider>
                                        </b-col>
                                        <b-col md="6">
                                            <ValidationProvider                                                    
                                                rules="required|min:3"
                                                v-slot="{ failedRules }"
                                            >
                                                    <b-form-group
                                                        id="contact-lname"
                                                        :label="tr('contact_input_lname')"
                                                        label-for="contactLastName"    
                                                        class="animateScroll"    
                                                        v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')"                        
                                                    >
                                                        <div class="form-input-wrap">
                                                            <b-form-input
                                                                id="contactLastName"
                                                                class="animateScroll"
                                                                v-model="form.lastname"
                                                                type="text"
                                                                :placeholder="tr('contact_input_lname_placeholder')"
                                                                @focus="focusedParent($event)"
                                                                @focusout="focusedOutParent($event)"
                                                                v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"
                                                                ></b-form-input>
                                                        </div>
                                                        <div class="error" id="first-name-error">
                                                            {{ getErrorMessage("surname", Object.entries(failedRules)[0]) }}
                                                        </div>
                                                    </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider                                                    
                                                rules="required|email"
                                                v-slot="{ failedRules }"
                                            >
                                                <b-form-group
                                                    id="contact-email"
                                                    label="E-mail"
                                                    label-for="contactEmail"    
                                                    class="animateScroll"       
                                                    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"                     
                                                >
                                                    <div class="form-input-wrap">
                                                        <b-form-input
                                                            id="contactEmail"
                                                            class="animateScroll"       
                                                            v-model="form.email"
                                                            type="text"
                                                            :placeholder="tr('contact_input_email_placeholder')"
                                                            @focus="focusedParent($event)"
                                                            @focusout="focusedOutParent($event)"
                                                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-4')"
                                                            ></b-form-input>
                                                    </div>
                                                    <div class="error" id="first-name-error">
                                                        {{ getErrorMessage("email", Object.entries(failedRules)[0]) }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider                                                    
                                                rules="required"
                                                v-slot="{ failedRules }"
                                            >
                                                <b-form-group
                                                    id="contact-phone"
                                                    class="animateScroll"       
                                                    :label="tr('contact_input_phone')"
                                                    label-for="contactPhone" 
                                                    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')"                               
                                                >
                                                    <div class="form-input-wrap">
                                                        <b-form-input
                                                            id="contactPhone"
                                                            class="animateScroll"       
                                                            v-model="form.phone"
                                                            type="text"
                                                            :placeholder="tr('contact_input_phone_placeholder')"
                                                            @focus="focusedParent($event)"
                                                            @focusout="focusedOutParent($event)"
                                                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-4')"
                                                            ></b-form-input>
                                                    </div>
                                                    <div class="error" id="first-name-error">
                                                        {{ getErrorMessage("phone", Object.entries(failedRules)[0]) }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <ValidationProvider                                                    
                                                rules="required|min:5"
                                                v-slot="{ failedRules }"
                                            >
                                                <b-form-group
                                                    id="contact-msg"
                                                    class="animateScroll"       
                                                    :label="tr('contact_input_msg')"
                                                    label-for="contactMsg"    
                                                    v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-5')"                            
                                                >
                                                    <div class="form-input-wrap">                                       
                                                        <textarea        
                                                            ref="msgTextarea"                                         
                                                            id="contactMsg"
                                                            class="animateScroll"       
                                                            v-model="form.message"                                                
                                                            :placeholder="tr('contact_input_msg_placeholder')"
                                                            @focus="focusedParent($event)"
                                                            @focusout="focusedOutParent($event)"
                                                            @input="setTextareaHeight()"
                                                            v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-6')"
                                                        ></textarea>
                                                    </div>
                                                    <div class="error" id="first-name-error">
                                                        {{ getErrorMessage("message", Object.entries(failedRules)[0]) }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12">
                                            <div class="contact__submit-row animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-7')">
                                                <ValidationProvider
                                                    rules="required|acceptConditions"
                                                    v-slot="{ failedRules }"
                                                >
                                                    <b-form-checkbox
                                                        id="condition"
                                                        v-model="form.condition"
                                                        name="condition"
                                                        value="1"
                                                        unchecked-value="0"
                                                    >
                                                        {{tr('contact_input_privacy_condition_1')}} <a :href="baseUrl + 'content/Luminor_adatkezelesi_tajekoztato.pdf'" target="_blank">{{tr('contact_input_privacy_condition_2')}}</a>
                                                    </b-form-checkbox>
                                                    <div class="error error--condition">
                                                        {{ getErrorMessage("condition",Object.entries(failedRules)[0])}}
                                                    </div>
                                                </ValidationProvider>
                                                <button type="submit" class="btn btn--black btn--arrow"><span>{{tr('contact_btn_send')}}</span></button> 
                                            </div>
                                        </b-col>                                        
                                    </b-row>                                                                   
                            </b-form>
                        </ValidationObserver>
                        <div class="success-msg" v-if="formSendSuccessful">
                            <img src="@/assets/images/icons/ic-sparkles-black.svg" />
                            {{tr('contact_msg_success_send')}}
                        </div>
                    </div>
                </b-col>
                <b-col lg="5" offset-xl="1">
                    <div class="contact__info animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">
                        <div class="name animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">Luminor Apartments</div>
                        <div class="phone animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-4')">
                            <div><img src="@/assets/images/icons/ic-phone-black.svg" /><span>Phone</span></div>
                            <a href="tel:+36306502550"> +36 30 650 2550</a> 
                        </div>
                        <div class="email animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-5')">
                            <div><img src="@/assets/images/icons/ic-mail-black.svg" /><span>E-mail</span> </div>
                            <a href="mailto:info@luminorapartments.com">
                                info@luminorapartments.com
                            </a>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
// import axios from "axios";
import apiHandlerMI from "@/mixins/apiHandlerMI"; 
import { required, email } from "vee-validate/dist/rules";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider} from "vee-validate";
// import errorMessages from "/public/errorMessages.json";

// Install VeeValidate components globally
setInteractionMode('eager')

extend("required", {
	...required	
});
extend("email", {
	...email	
});
extend("min", {
	validate(value, args) {
		return value.length >= args.length;
	},
	params: ["length"],
	message: "Not enough characters",
});
extend("acceptConditions", {
  validate(value) {
    if (parseInt(value) === 1) {
      return true;
    } else {
      return false;
    }
  }  
});

export default {
    mixins:[apiHandlerMI],
   data(){
       return {
           form:{
               firstname: '',
               lastname: '',
               email: '',
               phone: '',
               message: '',
               condition: ''
           },
           baseUrl: process.env.VUE_APP_BASE_URL,
           formSendSuccessful:false
       }
   },
   components:{
       ValidationObserver,
       ValidationProvider
   },
   methods:{
        focusedParent(event){                
            if (event) {                
                let focusedElement = event.target
                focusedElement.classList.add('focused')        
                focusedElement.parentNode.classList.add('focused')                
            }            
        },
        focusedOutParent(event){
            if (event) {                
                let focusedElement = event.target
                focusedElement.classList.remove('focused')        
                focusedElement.parentNode.classList.remove('focused')                
            }
        },
        setTextareaHeight(){
            let element = this.$refs.msgTextarea            
            element.style.height = `${element.scrollHeight}px`;            
        }, 
        // getErrorMessage(fieldName, violation) {
        //     return violation ? (errorMessages[fieldName] && errorMessages[fieldName][violation[0]]) ?? violation[1] : undefined;
        // },  
        onSubmit() {
            let _this = this;

            // axios.post(process.env.VUE_APP_API_URL + 'emailsender/1/1', {
            //     to: _this.CONFIG.contactEmail,
            //     data_replace: {
            //         name: _this.form.firstname + ' ' + _this.form.lastname,                
            //         email: _this.form.email,
            //         phone: _this.form.phone,                
            //         message: _this.form.message,
            //         condition: _this.form.condition            
            //     }                
            // })
            // .then(() => {
            //     // _this.showModal();
            //     _this.resetForm();     
            //     _this.formSendSuccessful = true  
                
            //     setTimeout(() => {
            //         _this.formSendSuccessful = false
            //     }, 4000);
            // })
            // .catch(function (error) {
            //     console.log(error);
            // });
            _this.post('emailsender/1/1', 
            {
                to: _this.CONFIG.contactEmail,
                data_replace: {
                    name: _this.form.firstname + ' ' + _this.form.lastname,                
                    email: _this.form.email,
                    phone: _this.form.phone,                
                    message: _this.form.message,
                    condition: _this.form.condition            
                }                
            },
            {},
            function(){
                _this.resetForm();     
                _this.formSendSuccessful = true  
                
                setTimeout(() => {
                    _this.formSendSuccessful = false
                }, 4000);
            }, 
            function(error){
                console.log(error);
            })
        },
        resetForm() {
            this.form.firstname = "";
            this.form.lastname = "";
            this.form.phone = "";            
            this.form.message = "";
            this.form.email = "";
            this.form.condition = "";          
            
            // reset the validation observer - prevent the error messages write after the form send
            this.$refs.contactform.reset()
        }, 
   }
}
</script>