<template>
    <div class="services">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')" v-html="tr('home_services_title')"></h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
                      <OwlCarousel  :items="4" :margin="40" :dots="true"  :loop="false" :nav="false"
                                :responsive="{ 
                                    0:{
                                        items:1,														
                                        margin: 0,
                                        mouseDrag: true,
                                        loop:true,
                                        dots:true                                        
                                    },      													
                                    768:{items:4, mouseDrag:true, margin: 30},
                                    992:{items:4, mouseDrag:true, margin:20}
                                }">
                                <div class="img-wrap-outer" 
                                    v-for="(slide, sindex) in slides" :key="sindex"                                 
                                >              
                                    <div class="img-wrap">
                                        <img :src="'/pictures/services/slider/' + slide.src" class="img-fluid"  />
                                    </div>                           
                                    <div class="name animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">{{slide.name[selectedLanguage]}}</div>
                                    <div class="text animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">{{slide.text[selectedLanguage]}}</div>
                                </div> 
                        </OwlCarousel>
                        <div class="d-flex justify-content-center">
                            <router-link :to="{name: 'services'}" class="btn btn--black btn--arrow animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-4')">
                                <span>{{tr('btn_learn_more')}}</span>
                            </router-link>
                        </div>
                </b-col>
            </b-row>            
        </b-container>        
    </div>
</template>


<script>
import OwlCarousel from 'vue-owl-carousel'

export default {
    components:{
        OwlCarousel
    },
    data(){
        return{
            slides:[                
                {
                    src: 'fitness.jpg',
                    name: {
                        "hu": 'Fitness',
                        "en": "Fitness"
                    },
                    text: {
                        "hu": 'Lendüljön formába a legmodernebb Technogym felszerelésekkel',
                        "en": "Get in shape with the most advanced Technogym equipment"
                    }
                },
                {
                    src: 'concierge.jpg',
                    name: {
                        "hu":'Portaszolgálat',
                        "en": "Reception"
                    },
                    text: {
                        "hu":'0-24 órás biztonsági szolgálat, napközben elérhető concierge szolgáltatással',
                        "en": '24-hour security and concierge service during the day'
                    }
                },
                {
                    src: 'wellness.jpg',
                    name: {
                        "hu": 'Wellness',
                        "en": "Wellness"
                    },
                    text: {
                        "hu":'Kényeztesse magát Wellness központunkban',
                        "en": 'Relax in our exclusive wellness centre'
                    }
                },
                {
                    src: 'pool.jpg',
                    name: {
                        "hu":'Uszoda',
                        "en": 'Swimming Pool'
                    },
                    text: {
                        "hu":'Első osztályú úszómedence a vízi sportok kedvelőinek',
                        "en": 'First class swimming pool for water sports enthusiasts'
                    }
                }                
            ],                    
        }
    }
}
</script>