<template>
    <section class="hero">        
        <b-container fluid="xl" class="h-100 hero__text">
            <b-row class="h-100">
                <b-col cols="12" md="6" class="h-100">
                    <div class="hero__blocks-wrap h-100" >
                        <div class="hero__block-top">                            
                            <div class="hero__lead-wrap">
                                <h2 class="animateScroll fade-up delay-2" v-html="tr('home_hero_main_text')"></h2>
                            </div>
                        </div>
                        <div class="hero__block-bottom animateScroll fade-up delay-4 d-none d-md-block">
                            <button v-scroll-to="'#sectionProject'" type="button" class="btn btn--scroll" title="Scroll"></button>
                        </div>
                    </div>
                </b-col>                
            </b-row>
        </b-container>
        <div class="hero__main-img ">            
            <div class="hero__block-bottom animateScroll fade-up delay-4 d-md-none">
                <button v-scroll-to="'#sectionProject'" type="button" class="btn btn--scroll" title="Scroll"></button>
            </div>            
            <div class="hero__main-img-wrap">
                <!-- <div class="lazy-image animateScroll fade-down" 
                    :style="{'background-image' :'/pictures/hero/hero-img-loading.jpg'}" 
                    data-image-full="/pictures/hero/hero-img.jpg"
                >
                    <img src="/pictures/hero/hero-img-loading.jpg" alt="Luminor apartments" class="" />
                </div> -->
                <img src="/pictures/hero/hero-img.jpg" alt="Luminor apartments" class="animateScroll fade-down--img" />
            </div>
        </div>
    </section>
</template>

<script>

export default {
    data(){
        return{
            
        }
    },
    methods:{
       
    }
}
</script>